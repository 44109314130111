import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import verification_page_image from '../assets/login_page_ayat.png'; // Replace with your image
import verification_page_text from '../assets/login_page_ayat_meaning.png'; // Replace with your image
import '../Routes/home.css';
import config from '../config';
import AuthContext from '../context/AuthContext';

const bgColorChange = keyframes`
  0% { background-color: #12B48D; }
  100% { background-color: #12B48D; }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: ${bgColorChange} 10s infinite;
  text-align: center;
  padding: 20px;

  background: #12B48D;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const AnimatedText = styled(motion.h1)`
  font-size: 2em;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const GoBackButton = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  color: #12B48D;
  outline: none;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #12B48D;
    color: #ffffff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 8px 16px;
  }
`;

const textVariants = {
  animate: {
    y: [0, -20, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
};

const VerifyEmailChange = () => {
  const { uid, token } = useParams(); // Extract uid and token from URL
  const [verified, setVerified] = useState(false); // Tracks email verification status
  const [error, setError] = useState(null); // Tracks errors
  const API_URL = config.authUrl;
  const { logoutUser, user, authTokens } = useContext(AuthContext);
  const authToken = authTokens?.access;

  // Verify email when the component mounts
  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${API_URL}verify-email-change/${uid}/${token}/`, {
            headers: {
              Authorization: `Bearer ${authToken}`, // Pass the JWT token in the Authorization header
            }
        },);
        if (response.status === 200) {
          setVerified(true); // Mark as verified if successful
        }
      } catch (error) {
        setError('Email verification failed. The link may be expired or invalid.'); // Set error on failure
      }
    };

    verifyEmail();
  }, [API_URL, uid, token]);

  return (
    <PageContainer>
      <ContentContainer>
        <div className="home-middle-quranic-verse">
          <img src={verification_page_image} className="verification_page_image" alt="verification" />
          <img src={verification_page_text} className="verification_page_text" alt="verification-text" />
        </div>

       {
        
       user?( <div>
          {verified ? (
            <AnimatedText>
              Your email has been successfully verified! You can now 
              <br/>
              <Link to="/">
                <GoBackButton whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                  Go Back
                </GoBackButton>
              </Link>
            </AnimatedText>
          ) : error ? (
            <AnimatedText>{error}</AnimatedText>
          ) : (
            <AnimatedText variants={textVariants} animate="animate">
              Verifying your email, please wait...
            </AnimatedText>
          )}
        </div>):(
          <AnimatedText>
         You are not Logged in
          <br/>
          <Link to="/">
            <GoBackButton whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
              Go Back
            </GoBackButton>
          </Link>
        </AnimatedText>
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default VerifyEmailChange;
